import React from 'react';
import './App.css';
import {BrowserRouter, useRoutes} from "react-router-dom";
import Home from "./routes/home/Home";
import AboutUs from "./routes/about-us/AboutUs";
import PrivacyPolicy from "./routes/privacy-policy/PrivacyPolicy";
import TermsOfService from "./routes/terms-of-service/TermsOfService";
import DataProtection from "./routes/data-protection/DataProtection";
import ApplyNow from "./routes/apply-now/ApplyNow";
import NotFound from "./routes/not-found/NotFound";

const AppRouter = () => {
    return useRoutes([
        { path: "/", element: <Home/> },
        { path: "/about-us", element: <AboutUs/> },
        { path: "/apply-now", element: <ApplyNow/> },
        { path: "/privacy-policy", element: <PrivacyPolicy/> },
        { path: "/terms-of-service", element: <TermsOfService/> },
        { path: "/data-protection", element: <DataProtection/> },
        { path: "*", element: <NotFound/> },
    ]);
};

function App() {
    return (
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
    );
}

export default App;
