import React from "react";
import "./toggle-visibility.css"

interface Show {
  show: boolean;
}

export default class ToggleVisibility extends React.Component<{ className: string, buttonText: string, children: any }, Show> {
  constructor(props: any) {
    super(props);

    this.state = {
      show: false
    };

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow() {
    this.setState({...this.state, show: !this.state.show})
  }

  render() {
    let {buttonText, children, className} = this.props;

    return (
      <div>
        <button className={className} onClick={this.toggleShow}>{buttonText}</button>
        {this.state.show && children}
      </div>
    );
  }
}