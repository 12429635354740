import React from 'react';

import '../../index.css'
import './TermsOfService.css';

import Heading from "../../components/heading/Heading";
import Footer from "../../components/footer/Footer";
import emailInfoContact from '../../utils/emailInfoContact';

function TermsOfService() {
  return (
    <div className="home">
      <Heading/>
      <div className="text-content-center">
        <h1 className="center">TERMS OF SERVICE</h1>
        <p className="grouped"><b>TERMS OF BUSINESS</b></p>
        <p className="grouped">Registered Business Name – How We Conduct Business:</p>
        <p className="grouped">Terms and Conditions &amp; Complaints Handling Policy</p>
        <p className="grouped">The following information informs you about our business, the services we provide to Clients, how we conduct business</p>
        <p className="grouped">including charges for the services we provide and commissions we may receive. It also sets out policy over the general</p>
        <p className="grouped">usage of this web site and our copyright protection.</p>
        <br/>
        <p className="grouped"><b>ABOUT THE COMPANY</b></p>
        <p className="grouped">Trading Name: Pinnacle Asset Finance Ltd</p>
        <p className="grouped">Registered Company Name: Pinnacle Asset Finance Ltd, Referred to as ‘The Company’</p>
        <p className="grouped">Company Registration Number: 14776632</p>
        <p className="grouped">Company Registered Address: 9 The Mount, Aspley Guise, MK17 8DZ</p>
        <p className="grouped">Main Office Address for Postal Communication: 9 The Mount, Aspley Guise, MK17 8DZ</p>
        <p className="grouped">E-Mail Address for Communication: {emailInfoContact()}</p>
        <p className="grouped">Telephone Contact Numbers: 07484697244</p>
        <br/>
        <p className="grouped"><b>Financial Conduct Authority (FCA)</b></p>
        <p className="grouped">The Company is Authorized and Regulated by the Financial Conduct Authority (FCA) under the Firm Reference Number (FRN): XXX</p>
        <p className="grouped">The Company operates as an Appointed Representative of Principal Firm: White Rose Finance Group Ltd who have a Firm</p>
        <p className="grouped">Reference Number of 630772</p>
        <p className="grouped">More information can be obtained by searching the Financial Conduct Authority register: https://www.fca.org.uk/register</p>
        <br/>
        <p className="grouped"><b>Data Protection Registration</b></p>
        <p className="grouped">The Company is registered with the Information Commissioners Office for Data Protection Purposes.</p>
        <p className="grouped">More information can be obtained from the Information Commissioners Office web site: https://www.ico.org.uk</p>
        <br/>
        <p className="grouped"><b>HOW WE CONDUCT BUSINESS</b></p>
        <p className="grouped">The Company operates in the market as a Credit Broker, we are not a Lender and we do not lend our own money.</p>
        <p className="grouped">As a Credit Broker we provide a range of financial brokerage services to business and personal clients (herein referred to as ‘the Client’).</p>
        <p className="grouped">The Company is not tied to any lender or group of lenders and operates on a whole of market basis, we source appropriate financial services and products for our clients from a broad range number of lending organizations with whom the company has a professional broker agreement.</p>
        <p className="grouped">The policy of the Company is to provide our clients an unbiased and impartial service and to recommend the most appropriate and cost-effective financial products matching their declared needs, borrowing requirements and personal / business financial means and circumstances.</p>
        <br/>
        <p className="grouped">OUR DUTY OF CARE AND POLICY ON TREATING CUSTOMERS FAIRLY</p>
        <p className="grouped">The Company works under the control of the Financial Conduct Authority (FCA) and is authorized and regulated as detailed above.</p>
        <br/>
        <p className="grouped">In dealing with Clients our duty of care dictates that it all cases the recommendation we make will be based on the best and most appropriate solution for the client given the information provided to the Company.</p>
        <p className="grouped">In providing our services and support to a client we will normally request ‘Fact Finding’ information pertaining to the overall financial means of the Client, Asset and Liability position, credit status and general personal / company profile.</p>
        <p className="grouped">All information provided by Clients to the Company in this regard is treated as confidential and will be handled in accordance with the Data Protection Act as set out by the Information Commissioners Office under which the Company is registered as detailed above.</p>
        <p className="grouped">More details on data protection can be obtained from the Information Commissioners Office (ICO) via the web site: https://www.ico.org.uk</p>
        <br/>
        <p className="grouped"><b>DEBT SERVICEABILITY / VULNERABLE CLIENTS POLICY / CLIENT RISKS</b></p>
        <p className="grouped">Our duty of care and regulatory obligations dictate that in all Client engagements we should take all possible steps to ensure that the following conditions are met:</p>
        <ol>
          <li className="grouped">Any product or service we are recommending to a client can be demonstrated to be adequately affordable to the client without the risk of financial hardship occurring either at the time of engagement or if circumstances change during the term of the loan, lease or mortgage. This is referred to as ‘Debt Serviceability Criteria’ and if, in the view of the Company, the requested borrowing requirements cannot be adequately afforded this advice will be provided to the Client.</li>
          <li className="grouped">That we take all possible steps to identify any ‘Vulnerable Clients’ who, and for whatever reason, may not be in a position to fully understand the terms and conditions of any financial service being requested. In such cases the Company will provide appropriate advice to the Client.</li>
          <li className="grouped">That we advise all Clients of the associated risks and possible consequences should they not be able to make the regular scheduled payments during the agreed term for any loan, lease or mortgage etc. taken out.</li>
        </ol>
        <br/>
        <p className="grouped"><b>CREDIT PROFILE SEARCHES</b></p>
        <p className="grouped">In providing our services it may be necessary for a recommended Lender to complete a formal credit search on a client or the Clients business and if this is required the Company will always seek the prior approval of the Client before such a search is carried out.</p>
        <p className="grouped">In completing any of the Personal Financial Profile or Business Financial Profile or general fact-finding documents that we may provide to you in the course of providing our services, it is considered implicit that you agree to such credit searches being carried out.</p>
        <br/>
        <p className="grouped"><b>SERVICES PROVIDED TO THE CLIENT AT NO CHARGE AND WITHOUT</b></p>
        <p className="grouped"><b>OBLIGATION</b></p>
        <p className="grouped">A minimum of one free consultation is provided to all our clients and by arrangement this may be conducted either via telephone or a face-to-face meeting either at The Company offices or the Clients premises.</p>
        <p className="grouped">Following the initial free consultation(s) and fact finding process the company will advise the client on the most appropriate and cost-effective actions to achieve the clients stated financial objectives and borrowing requirements.</p>
        <p className="grouped">The advice and recommendations will be delivered to the Client in the form of a summary report issued by the Company.</p>
        <p className="grouped">Under normal circumstances the report will contain a recommended Lenders Agreement in Principle (AIP) or a number of alternative options for consideration to support the borrowing requirement, and also a Funding Services Agreement (FSA).</p>
        <p className="grouped">The AIP will contain details of the in principle offer(s) obtained for the client to consider along with lending rates, fees and other charges which may be payable.</p>
        <p className="grouped">The FSA will detail the schedule of services to be provided to the Client by the Company should they wish to proceed, the terms of engagement and any fees that the company will charge for providing its services and when these fees fall due.</p>
        <br/>
        <p className="grouped">The FSA will point out the risks to the Client should they fail to keep up with the agree payment schedule on any Loan,</p>
        <p className="grouped">Lease or Mortgage being arranged on their behalf.</p>
        <p className="grouped">There is no obligation on the Client to accept any offer of funding recommended by the Company by way of the summary report and the Company will not apply any charges for the services provided to that point.</p>
        <p className="grouped">The client has the opportunity to review the summary report (AIP and FSA) as referred to above and to instruct the company to proceed or otherwise. Should the client wish to proceed he will be asked to sign and return the FSA document which is a formal and binding agreement under these terms of business. Upon receipt the company will initiate all necessary action to provision the financial service(s) as outlined in the summary report (AIP and FSA).</p>
        <br/>
        <p className="grouped"><b>COMMISSIONS RECEIVED AND CHARGES FOR OUR SERVICES</b></p>
        <p className="grouped">The company or its Case Packager may receive commissions paid by the lending organizations with whom it has brokerage agreements and to whom it may recommend the Client.</p>
        <p className="grouped">If a commission payment is to be received from Lenders either to the Company or to a Packager this will be fully disclosed to the Client and will be stated either on the summary report (AIP and FSA) as referred to above OR in the loan, lease or mortgage offer issued to the client directly from the lender.</p>
        <p className="grouped">The Company may also charge fees directly to the client for the provision of its services including the arranging and managing finance applications through to successful completion. Any fees to be charged will we clearly stated to the client.</p>
        <p className="grouped">Other than any requested advance commitment fee requested to cover expenses incurred by the Company the fees charged by the company will only be applied on successful conclusion of the financial service transaction.</p>
        <p className="grouped">The definition of ‘successful conclusion’ means the delivery to the Client of a formal offer of funding from a recommended lender pertaining to any loan, equipment lease, commercial mortgage, cash-flow solution or any other financial service</p>
        <p className="grouped">which has been requested by the Client and under which matter which the Company has received a formal instruction to proceed.</p>
        <p className="grouped">For the avoidance of doubt, other than services which would be deemed to fall under the Consumer Credit Act (2006) should the Company deliver to the client the formal offer of funding and the client (for whatever reason) decides not to complete the transaction then the agreed fee remains due for payment in full.</p>
        <br/>
        <p className="grouped"><b>CASE PACKAGING AND LOCUM SERVICES</b></p>
        <p className="grouped">In providing services to Clients the Company will generally use the case packaging and Locum support services of its FCA</p>
        <p className="grouped">Principal firm which is White Rose Finance Group Ltd.</p>
        <p className="grouped">The term ‘Packaging’ in this context refers to the preparation and submission of any lender application documentation and supporting information in to the proposed lender in order to secure and deliver a formal offer of funding to the Client.</p>
        <p className="grouped">The term ‘Locum Support’ in this context means the provision of ongoing support services to the Client in the event that the Company cannot, for whatever reason (including staff illness or holiday periods) provide continuity of service.</p>
        <br/>
        <p className="grouped"><b>OUR COMPLAINTS HANDLING POLICY</b></p>
        <p className="grouped">The Company works hard to provide a consistently high level of service to all Clients. Should any Client have a grievance or complaint to make to The Company or the services provided this should be sent by recorded delivery post marked for the attention of the Managing Director at the Company Main Office address as shown above.</p>
        <p className="grouped">In registering a complaint with the Company please include as much detail as possible regarding the specific nature of the grievance you have including the names of any staff members of the Company that you have been dealing with.</p>
        <p className="grouped">All complaints registered with the Company will be acknowledged within five business days and all matters relating to the complaint will be handled either in person by the Managing Director of the Company or by a nominated senior member of staff.</p>
        <br/>
        <p className="grouped">Every effort will be made to resolve the complaint or grievance as quickly as possible and to the full satisfaction of the Client.</p>
        <br/>
        <p className="grouped"><b>USE OF THIS WEB SITE</b></p>
        <p className="grouped">Please review the following terms and conditions concerning your use of the Web Site. By accessing, using or downloading any materials from the Web Site, you agree to follow and be bound by these terms and conditions (the “Terms”). If you do not agree with these Terms, please do not use this Web Site.</p>
        <br/>
        <p className="grouped"><b>GENERAL WEB SITE USAGE PROVISIONS</b></p>
        <p className="grouped">All materials provided on this Web Site, including but not limited to information, documents, products, logos, graphics, sounds, images, software, and services (“Materials”), are provided either by The Company or by their respective third- party manufacturers, authors, developers and vendors (“Third Party Providers”) and are the copyrighted work of The Company and/or its third-party Providers.</p>
        <p className="grouped">Except as stated herein, none of the Materials may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including but not limited to electronic, mechanical, photocopying, recording, or other means, without the prior express written permission of The Company or the third-party Provider. Also, you may not “mirror” any Materials contained on this Web Site on any other server without prior express written permission. Except where expressly provided otherwise by The Company nothing on this Web Site shall be construed to confer any license under any of The Company or any third-Party Provider’s intellectual property rights, whether by implication, or otherwise you acknowledge sole responsibility for obtaining any such licenses.</p>
        <p className="grouped">See the “Legal Contact Information” below if you have any questions about obtaining such licenses. Materials provided by</p>
        <p className="grouped">Third Party Providers have not necessarily been independently reviewed, tested, certified, or authenticated in whole or in part. The Company does not promote, recommend, provide, sell, license, or lease any of the Materials other than those specifically identified as being provided by The Company. The Company hereby grants you permission to display, copy, distribute and download The Company materials on this Web Site provided that: (1) both the copyright notice identified below and this permission notice appear in the Materials; (2) the use of such Materials is solely for informational use and will not be copied or posted on any networked computer, broadcast in any media, or used for unofficial dissemination; and (3) the Materials are not modified in any way.</p>
        <p className="grouped">This permission terminates automatically without notice if you breach any of these terms or conditions. Upon termination, you will immediately destroy any downloaded or printed Materials. Any unauthorized use of any Materials contained on this Web Site may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.</p>
        <br/>
        <p className="grouped"><b>LINKS TO THIRD PARTY SITES</b></p>
        <p className="grouped">This Web site may contain links to web sites controlled by parties other than The Company who are not responsible for and do not endorse or accept any responsibility for the contents or use of these third-party web sites. It is your responsibility to take precautions to ensure that whatever you select for your use is free of viruses or other items of a destructive nature.</p>
        <br/>
        <p className="grouped"><b>DISCLAIMER</b></p>
        <p className="grouped">Where expressly provided otherwise by The Company the materials on the web site are provided “as is”, and The Company hereby disclaims all express or implied representations, warranties, guaranties, and conditions, including but not limited to any implied warranties or conditions of merchantability, fitness for a particular purpose, title and non- infringement, except to the extent that such disclaimers are held to be legally invalid. The Company makes no representations, warranties, guaranties, or conditions as to the quality, suitability, truth, accuracy, or completeness of any of the materials contained on the web site. limitation of liability shall not be liable for any damages suffered as a result of using, modifying, contributing, copying, distributing, or downloading the materials. in no event shall The Company be liable for any indirect, punitive, special, incidental, or consequential damage (including but not limited to loss of business, revenue, investment, profits, use, data or other economic advantage), however it arises, whether in an action of contract, negligence or other tortious action, arising out of or in connection with the use or performance of information available from this web site, even if The Company has been previously advised of the possibility of such damage. you have sole responsibility for the adequate protection and backup of data and/or equipment used in connection with the web site and you will not make a claim against The Company for lost data, re-run time, inaccurate output, work delays, or lost profits resulting from the use of the materials. you agree to hold The Company harmless from, and you covenant not to sue The Company for, any claims based on or related to the use of the web site.</p>
        <p className="grouped">The Company and its suppliers control this Web Site from its headquarters in the United Kingdom and makes no representation that these Materials are appropriate or available for use in other locations.</p>
        <p className="grouped">The service is hosted by a third party in the UK at a location other than the company headquarters and as such you should be aware that any data that you submit travels to a secure server in another location. If you use this Web Site from other locations, you are responsible for compliance with applicable local laws including but not limited to the export and import regulations of other countries.</p>
        <br/>
        <p className="grouped"><b>GENERAL INFORMATION PROVIDED</b></p>
        <p className="grouped">This Web Site may include inaccuracies or typographical errors. The Company and the third-party Providers may make improvements and/or changes in the products, services, programs, and prices described in this Web Site at any time without notice.</p>
        <p className="grouped">The Company may periodically make changes to the Web Site. Any action related to these Terms will be governed by the law prevailing in England and Wales and any superior European Union Law. These terms represent the entire understanding relating to the use of the Web Site and prevail over any prior or contemporaneous, conflicting or additional, communications. The Company has the right to revise these Terms at any time without notice by updating this posting. Any rights not expressly granted herein are reserved by The Company</p>
        <br/>
        <p className="grouped"><b>INTELLECTUAL PROPERTY AND TRADEMARK NOTICES</b></p>
        <p className="grouped">Elements of the Web Site may be protected by trade mark protection and other laws and may not be copied or imitated in whole or in part. No logo, graphic, sound or image from the Web Site may be copied or re-transmitted unless expressly permitted by The Company.</p>
        <p className="grouped">The Company logo is a registered trademark and copyright protected. It must not be copied or reproduced in any way.</p>
        <br/>
        <p className="grouped"><b>LEGAL CONTACT</b></p>
        <p className="grouped">If you have any questions about these Terms, or if you would like to request permission to use any Materials, please contact The Company by post at the company main office address as stated above.</p>
        <p className="grouped">Pinnacle Asset Finance Ltd (company number: 14776632) Registered at 9 The Mount, Aspley Guise, Bedfordshire, MK17 8DZ</p>
        <p className="grouped">Pinnacle Asset Finance Ltd (FRN: ) is a credit broker and appointed representative of White Rose Finance Group Limited (FRN: 630772) who are directly authorised and regulated by the Financial Conduct Authority.</p>
        <p className="grouped">We comply fully with the General Data Protection Regulations (GDPR) 2018 and all client data and information you provide to us will be managed, processed and kept secure in accordance with the GDPR. We will never share, sell or distribute client data to any other third party other than Banks, Lenders, Brokers or Packagers whom we believe may serve the best interests of you the client.</p>
        <p className="grouped">Please make borrowing decisions carefully, property or other assets offered as security may be at risk if you cannot keep up with repayments.</p>
      </div>
      <Footer/>
    </div>
  );
}

export default TermsOfService;