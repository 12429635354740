import React from 'react';

import '../../index.css'
import './Home.css';

import Heading from "../../components/heading/Heading";
import Footer from "../../components/footer/Footer";
import About from "../../components/about/About";
import emailInfoContact from "../../utils/emailInfoContact";
import Apply from "../../components/apply/Apply";
import RoundedBox from "../../components/rounded-button/RoundedBox";

function Home() {
  return (
    <div className="home">
      <Heading/>
      <div className="image-backdrop">

      </div>
      <div className="content">
        <div className="image-content">
          <strong className="image-content-center">Bespoke funding solutions, tailored to your requirement.</strong>
          <div className="image-content-center-button-margin">
            <a href={"./apply-now"} className="rounded-button-style"><RoundedBox>APPLY NOW!</RoundedBox></a>
          </div>
        </div>
        <div className="image-content-bottom title-style">
          <p>{emailInfoContact()}</p>
        </div>
      </div>
      <div className="text-content-center">
        <Apply/>
      </div>
      <br/>
      <div className="inverted-color">
        <div className="text-content-center">
          <About/>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Home;
