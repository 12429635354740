import React from 'react';

import '../../index.css'
import './ApplyNow.css';

import Heading from "../../components/heading/Heading";
import Footer from "../../components/footer/Footer";
import Apply from "../../components/apply/Apply";


function ApplyNow() {
  return (
    <div className="home">
      <Heading/>
      <div className="text-content-center">
        <Apply/>
      </div>
      <Footer/>
    </div>
  );
}

export default ApplyNow;
