import React from 'react';
import './About.css';
import ToggleVisibility from "../toggle-visibility/toggle-visibility";

function About() {
  return (
    <div className="about">
      <div>
        <strong className="title-style title-text">ABOUT PINNACLE</strong>
      </div>
      <ul className="ul-no-padding">
        <li className="about-item">
          <ToggleVisibility buttonText="WHAT IS PINNACLE FINANCE?" className="about-button-style title-style">
            <p className="about-info">
              Pinnacle Finance are finance and mortgage brokers. We can help to arrange funding for accountants,
              landlords, business owners and more.
            </p>
            <p className="about-info">
              We are genuinely independent and impartial, offering straightforward, honest advice.
            </p>
            <p className="about-info">
              Time and again we prove to our clients that we can secure funding where others fail and, in the
              majority
              of cases, our advice will save you very significant amounts of money over the course of the loan.
            </p>
            <p className="about-info">
              The majority of specialist lenders in the current marketplace will only accept qualified and packaged
              mortgage and loan proposals from Specialist Brokers such as ourselves.
            </p>
            <p className="about-info">
              Pinnacle Asset Finance Ltd (company number: 14776632) Registered at 9 The Mount, Aspley Guise,
              Bedfordshire, MK17 8DZ
            </p>
          </ToggleVisibility>
        </li>
        <li className="about-item">
          <ToggleVisibility buttonText="WHY USE PINNACLE?" className="about-button-style title-style">
            <p className="about-info">
              Pinnacle is a bespoke financial brokerage dedicated to finding you the cheapest and best funding
              solution.
            </p>
            <p className="about-info">
              We are able to offer market leading rates, as we are smaller broker operation, enabling us to charge
              lower commission fees due to our low overhead costs.
            </p>
          </ToggleVisibility>
        </li>
        <li className="about-item">
          <ToggleVisibility buttonText="OUR SERVICES" className="about-button-style title-style">
            <b className="about-info about-subheader">
              Business Funding
            </b>
            <p className="about-info">
              Support your business with asset finance, business loans or invoice financing. With access to over 100
              UK based lenders we will be able acquire the perfect funding solution for your business.
            </p>
            <b className="about-info about-subheader">
              Commercial Mortgages
            </b>
            <p className="about-info">
              We can support a wide variety of commercial funding types including property development funding,
              buy-to-let and more.
            </p>
          </ToggleVisibility>
        </li>
        <li className="about-item">
          <ToggleVisibility buttonText="HOW WE CAN HELP" className="about-button-style title-style">
            <p className="about-info">
              We offer all our clients a true ‘one-stop shop’ facility, our core competence is to provide you with a
              fully managed and professional service to secure the most competitive funding offer available from the
              entire market.
            </p>
            <p className="about-info">
              In addition, we can, if required, organise any property valuations, insurance and legal support
              necessary to complete the transaction.
            </p>
            <p className="about-info">
              We will fully package and submit the lending proposal to ensure your funding application is processed
              quickly and efficiently with funds available in the shortest possible time.
            </p>
          </ToggleVisibility>
        </li>
      </ul>
    </div>
  );
}

export default About;
