import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p>Pinnacle Asset Finance Ltd (company number: 14776632) Registered at 9 The Mount, Aspley Guise, Bedfordshire, MK17 8DZ</p>
      <p className="footer-grouped">Pinnacle Asset Finance Ltd (FRN: 996840) is a credit broker and appointed representative of White Rose Finance Group Limited (FRN: 630772) who are directly authorised and regulated by the Financial Conduct Authority.</p>
      <p className="footer-grouped">We comply fully with the General Data Protection Regulations (GDPR) 2018 and all client data and information you provide to us will be managed, processed and kept secure in accordance with the GDPR. We will never share, sell or distribute client data to any other third party other than Banks, Lenders, Brokers or Packagers whom we believe may serve the best interests of you the client. </p>
      <p className="footer-grouped">Please make borrowing decisions carefully, property or other assets offered as security may be at risk if you cannot keep up with repayments.</p>
    </div>
  );
}

export default Footer;
