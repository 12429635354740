import React from 'react';
import './Apply.css';

import {useState} from "react";
import RoundedBox from "../rounded-button/RoundedBox";

// useForm functional component
export const useForm = (callback: any, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  // onChange
  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({...values, [event.target.name]: event.target.value});
  };

  // onSubmit
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await callback(); // triggering the callback
  };

  // return values
  return {
    onChange,
    onSubmit,
    values,
  };
}

function ApplyForm() {
  // defining the initial state for the form
  const initialState = {
    name: "",
    businessName: "",
    phone: "",
    email: "",
    fundingRequirement: "",
  };

  // getting the event handlers from our custom hook
  const {onChange, onSubmit, values} = useForm(
    submitted,
    initialState
  );

  // const applyUrl = "http://192.168.1.71:3001/apply";
  const applyUrl = "https://pinnacle-finance.co.uk/api/v1/apply";

  // a submit function that will execute upon form submission
  async function submitted() {
    let response = await fetch(applyUrl, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {'Content-Type': "application/json"}
    });

    if (response.ok) {
      alert("success");
    } else {
      alert("fail");
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        <div className="form-large-item">
          <input
            className="form-item"
            name='name'
            id='name'
            type='name'
            placeholder='Name*'
            onChange={onChange}
            required={true}
          />

          <input
            className="form-item"
            name='businessName'
            id='businessName'
            type='businessName'
            placeholder='Business Name*'
            onChange={onChange}
            required={true}
          />

          <input
            className="form-item"
            name='phone'
            id='phone'
            type='phone'
            placeholder='Phone*'
            onChange={onChange}
            required={true}
          />

          <input
            className="form-item"
            name='email'
            id='email'
            type='email'
            placeholder='Email*'
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="form-large-item">
        <textarea
          name='fundingRequirement'
          id='fundingRequirement'
          placeholder='Funding Requirement*'
          onChange={onChange}
          required={true}
        />
        </div>
      </div>
      <button type='submit' className="no-style-button rounded-button-style">
        <RoundedBox>Submit Application</RoundedBox>
      </button>
    </form>
  );
}

function Apply() {
  return (
    <div className="apply-now">
      <strong className="title-style title-text">APPLY NOW</strong>
      <p>Please fill in below, to give us an idea of your requirement.</p>
      <p className="sub-text">Once you have applied, we will be notified, and we will endeavour to reach out to you as
        soon as possible.</p>
      <ApplyForm/>
    </div>
  );
}

export default Apply;
