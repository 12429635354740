import React from 'react';
import './RoundedButton.css';

class RoundedBox extends React.Component<{children: any}> {
  render() {
    let {children} = this.props;
    return (
      <div className="rounded-button-wrapper">{children}</div>
    );
  }
}

export default RoundedBox;
