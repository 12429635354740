import React from 'react';

import '../../index.css'
import './NotFound.css';

import Heading from "../../components/heading/Heading";
import Footer from "../../components/footer/Footer";

function NotFound() {
  return (
    <div className="home">
      <Heading/>
      <div className="text-content-center">
        <h1>Oops, something went wrong!</h1>
        <h3 className="sub-text">
          Try going back to the <a href="./">
          <span className="highlighted-link">
            Home Page
          </span>
        </a></h3>
      </div>
      <Footer/>
    </div>
  );
}

export default NotFound;
