import React from 'react';

import '../../index.css'
import './AboutUs.css';

import Heading from "../../components/heading/Heading";
import Footer from "../../components/footer/Footer";
import About from "../../components/about/About";


function AboutUs() {
  return (
    <div className="home">
      <Heading/>
      <div className="text-content-center">
        <About/>
      </div>
      <Footer/>
    </div>
  );
}

export default AboutUs;
