/**
 * Info contact email info@pinnacle-finance.co.uk
 */
function emailInfoContact() {
  let decoded = atob("aW5mb0BwaW5uYWNsZS1maW5hbmNlLmNvLnVr");
  return (
    <a id="contact" href={"mailto:".concat(decoded)}>{decoded}</a>
  )
}

export default emailInfoContact