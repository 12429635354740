import React from 'react';
import logo from '../../logo.png';
import menuIcon from '../../menu-icon.svg';
import './Heading.css';
import '../../index.css'

interface State {
  isMobile: boolean;
}

class Heading extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 600,
    }

    window.addEventListener('resize', (event: Event) => {
      let wind: Window = event.target as Window;
      let isMobile = wind.innerWidth < 600;
      console.log(isMobile);
      this.setState({...this.state, isMobile: isMobile });
    });
  }

  render() {
    if (this.state.isMobile) {
      return (
        <div className="heading heading-padding-mobile">
          <a href={"./"}><img src={logo} className="item heading-logo hide_logo" alt="Pinnacle Finance Logo"/></a>
          <div className="item heading-title-wrapper heading-title heading-font-size"><div className="center-align"><a href={"./"}>PINNACLE<br/>ASSET FINANCE</a></div></div>
          <div className="item dropdown">
            <img className="menu-button" src={menuIcon} alt="Menu Button"/>
            <div className="dropdown-content-mobile">
              <div className="dropdown-content-inner dropdown-content-inner-mobile">
                <a href={"./"}><div className="dropdown-item item-right"><div className="center-align"><b>HOME</b></div></div></a>
                <a href={"./apply-now"}><div className="dropdown-item"><div className="center-align">APPLY NOW!</div></div></a>
                <a href={"./about-us"}><div className="dropdown-item center-align">ABOUT US</div></a>
                <a href={"./privacy-policy"}><div className="dropdown-item center-align">PRIVACY POLICY</div></a>
                <a href={"./terms-of-service"}><div className="dropdown-item center-align">TERMS OF SERVICE</div></a>
                <a href={"./data-protection"}><div className="dropdown-item center-align">DATA PROTECTION</div></a>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="heading heading-padding">
          <a href={"./"}><img src={logo} className="item heading-logo hide_logo" alt="Pinnacle Finance Logo"/></a>
          <div className="item heading-title-wrapper heading-title heading-font-size"><div className="center-align"><a href={"./"}>PINNACLE<br/>ASSET FINANCE</a></div></div>
          <div className="item item-right"><div className="center-align"><a href={"./"}><b>HOME</b></a></div></div>
          <div className="item"><div className="center-align"><a href={"./apply-now"}>APPLY NOW!</a></div></div>
          <div className="item dropdown">
            <div className="center-align">ABOUT US</div>
            <div className="dropdown-content">
              <div className="dropdown-content-inner">
                <a href={"./about-us"}><div className="dropdown-item center-align">ABOUT US</div></a>
                <a href={"./privacy-policy"}><div className="dropdown-item center-align">PRIVACY POLICY</div></a>
                <a href={"./terms-of-service"}><div className="dropdown-item center-align">TERMS OF SERVICE</div></a>
                <a href={"./data-protection"}><div className="dropdown-item center-align">DATA PROTECTION</div></a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Heading;